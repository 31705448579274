import React from "react"
import { ButtonContainer } from "./button-styles"
import { LocalizedLink } from "../Primitives/LocalizedLink"

export const Button = ({ text, primary, noPadding, to, action }) => {
  return (
    <>
      {to ? (
        <LocalizedLink to={"/" + to}>
          <ButtonContainer
            onClick={action}
            noPadding={noPadding}
            primary={primary}
            text={text}
          >
            {text && text}
          </ButtonContainer>
        </LocalizedLink>
      ) : (
        <ButtonContainer
          onClick={action}
          noPadding={noPadding}
          primary={primary}
          text={text}
        >
          {text && text}
        </ButtonContainer>
      )}
    </>
  )
}

export default Button
