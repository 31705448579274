import React from "react"
import styled from "styled-components"
import { PortView } from "../../slices-styles"

const TextContainer = styled.div`
  margin: 0 20px;

  @media (min-width: 800px) {
    margin: 0;
  }
  & > * {
    color: #343434;
  }
`

export const Text = ({ data }) => {
  const {
    primary: { text },
  } = data
  return (
    <PortView>
      <TextContainer dangerouslySetInnerHTML={{ __html: text && text.html }} />
    </PortView>
  )
}

export default Text
