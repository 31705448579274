import React from "react"
import Img from "gatsby-image"

export const Image = ({ data }) => {
  const {
    primary: { image },
  } = data

  return <Img fluid={image.localFile.childImageSharp.fluid} />
}

export default Image
