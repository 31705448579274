import React from "react"
import YouTube from "react-youtube"
import { PortView } from "../../slices-styles"

export const Video = ({ data }) => {
  const { video_url } = data.primary

  const opts = {
    height: "700px",
    width: "100%",
  }

  return (
    <PortView>
      <YouTube opts={opts} videoId={video_url} />
    </PortView>
  )
}
