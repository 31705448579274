import React from "react"

import {
  CardsSection,
  CardsWrapper,
  BackgroundImageWrapper,
  BackgroundImagePusher,
  GradientOverlay,
} from "./cards-styles"

import { Card } from "./Card"
import { StyledBackgroundComponent } from "../../../components/Primitives/BackgroundImage"

export const Cards = ({ data: { primary } }) => {
  const {
    left_card_label,
    right_card_label,
    left_card_heading,
    left_card_body,
    left_button_text,
    left_button_link_to,
    right_card_heading,
    right_card_body,
    right_button_text,
    right_button_link_to,
    background_image,
  } = primary
  return (
    <CardsSection>
      <CardsWrapper>
        <Card
          labelText={left_card_label && left_card_label}
          heading={left_card_heading && left_card_heading}
          body={left_card_body && left_card_body}
          buttonText={left_button_text && left_button_text}
          linkTo={left_button_link_to && left_button_link_to}
          bgColor="#39a5d6"
        />
        <Card
          labelText={right_card_label && right_card_label}
          heading={right_card_heading && right_card_heading}
          body={right_card_body && right_card_body}
          buttonText={right_button_text && right_button_text}
          linkTo={right_button_link_to && right_button_link_to}
          bgColor="#ff6e6e"
        />
      </CardsWrapper>

      <BackgroundImageWrapper style={{ position: "absolute", width: "100%" }}>
        <StyledBackgroundComponent
          imageData={background_image.localFile.childImageSharp.fluid}
          GradientOverlay
        >
          <BackgroundImagePusher>
            <GradientOverlay />
          </BackgroundImagePusher>
        </StyledBackgroundComponent>
      </BackgroundImageWrapper>
    </CardsSection>
  )
}
