import styled from "styled-components"

export const TextWrapper = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 800px) {
    width: 60%;
  }
`

export const TextContainer = styled.div`
  margin: 20px;

  @media (min-width: 800px) {
    margin-left: 10vw;
  }
`

export const TextHeading = styled.div`
  font-family: "HelveticaNeue Condensed";
  font-size: 1.3rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: 12.5px;
  text-align: left;
  color: #393939;
  text-transform: uppercase;

  & > * {
    margin: 0;
  }

  @media (min-width: 800px) {
    width: 60%;
    font-size: 2rem;
  }
`

export const TextBody = styled.div`
  width: 100%;
  @media (min-width: 800px) {
    width: 80%;
  }
`
