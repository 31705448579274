import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import i18n from "../../../config/i18n"

export const SEO = ({
  title,
  desc,
  banner,
  pathname,
  article,
  node,
  locale,
  author,
}) => {
  const { site } = useStaticQuery(query)

  //import default SEO values
  const {
    defaultTitle,
    defaultDescription,
    siteLanguage,
    headline,
    blog,
  } = i18n[locale]

  //import
  const {
    buildTime,
    siteMetadata: { siteUrl, defaultBanner, company },
  } = site

  const localizedPath = i18n[locale].default ? "" : `/${i18n[locale].path}`
  const homeURL = `${siteUrl}${localizedPath}`

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ""}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: homeURL,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: homeURL,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      "@type": "Person",
      name: "Matt Hamers",
    },
    copyrightHolder: {
      "@type": "Person",
      name: "Matt Hamers",
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: "Matt Hamers",
    },
    publisher: {
      "@type": "Person",
      name: "Matt Hamers",
    },
    datePublished: "2019-01-18T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  // Initial breadcrumb list
  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": siteUrl,
        name: "Homepage",
      },
      position: 1,
    },
    {
      "@type": "ListItem",
      item: {
        "@id": `${homeURL}/blog`,
        name: blog,
      },
      position: 2,
    },
  ]

  //check if article/blog in case yes, add structured data
  let schemaArticle = null

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name:
          `${author && author.first_name} ${author && author.last_name}` ||
          company,
      },
      copyrightHolder: {
        "@type": "Organization",
        name: company,
      },
      copyrightYear: "2019",
      creator: {
        "@type": "Organization",
        name: company,
      },
      publisher: {
        "@type": "Organization",
        name: company,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: "en",
      url: seo.url,
      name: seo.title,
      image: {
        "@type": "ImageObject",
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    }

    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": seo.url,
        name: seo.title,
      },
      position: 3,
    })
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="gatsby-starter" content="Gatsby Starter Prismic i18n" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
    </>
  )
}

export default { SEO }

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultBanner: banner
        ogLanguage
        company
      }
    }
  }
`
