import styled from "styled-components"

import { animated } from "react-spring"

export const Main = styled.div`
  position: relative;
  margin: 40px 0 0 0;
  height: 220px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (min-width: 800px) {
    margin-top: 0;
    height: calc(100vh - 350px);
  }
`

export const TextWrapper = styled.div`
  margin: 60px 20px 0 20px;
`

export const Text = styled(animated.h1)`
  will-change: transform, opacity;
  overflow: hidden;
  font-size: 1rem;
  letter-spacing: 14px;
  text-transform: uppercase;

  & > div {
    overflow: hidden;
  }

  @media (min-width: 800px) {
    will-change: transform, opacity;
    overflow: hidden;
    font-size: 2.5em;
    line-height: 1;
    letter-spacing: 30px;
  }
`
