import styled from "styled-components"

export const TextAndSliderWrapper = styled.section`
  padding: 40px 0;
  @media (min-width: 800px) {
    padding: 100px 0;
  }
`

export const TextAndSliderSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`
