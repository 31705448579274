import styled from "styled-components"

export const LabelWrapper = styled.div`
  position: absolute;
  height: 56px;
  top: -16px;
  left: 20px;
  display: flex;
  justify-content: center;
`
export const LeftBorder = styled.div`
  width: 20px;
  height: 100%;
  background-image: url(${({ label }) => label});
  transform: translateX(1px);
  background-size: cover;
`

export const RightBorder = styled.div`
  width: 11px;
  height: 100%;
  background-image: url(${({ label }) => label});
  background-position-x: right;
  transform: translateX(-1px);
  background-size: cover;
`
export const Text = styled.div`
  max-height: 52px;
  min-width: 100px;
  background-color: #343434;
  color: #ffbf00;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 7px;
  padding: 0 20px;
`
