import React from "react"

import { Text } from "./Text"
import { Slider } from "./Slider"

import {
  TextAndSliderWrapper,
  TextAndSliderSection,
} from "./textandslider-styles"

export const TextAndSlider = ({ data }) => {
  const { anchor_id } = data.primary

  return (
    <TextAndSliderWrapper id={anchor_id}>
      <TextAndSliderSection>
        <Text data={data.primary} />
        <Slider data={data.items} />
      </TextAndSliderSection>
    </TextAndSliderWrapper>
  )
}

export default TextAndSlider
