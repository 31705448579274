import React from "react"

import {
  TextWrapper,
  TextContainer,
  TextHeading,
  TextBody,
} from "./text-styles"

export const Text = ({ data: { title, text_body } }) => {
  return (
    <TextWrapper>
      <TextContainer>
        <TextHeading
          dangerouslySetInnerHTML={{ __html: title && title.html }}
        />
        <TextBody
          dangerouslySetInnerHTML={{ __html: text_body && text_body.html }}
        />
      </TextContainer>
    </TextWrapper>
  )
}

export default Text
