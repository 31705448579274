import styled from "styled-components"

export const CardsSection = styled.section`
  height: 100%;
  min-height: 500px;
`
export const CardsWrapper = styled.div`
  height: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 800px) {
    flex-direction: row;
    margin: 0 10%;
  }
`

export const BackgroundImageWrapper = styled.div`
  display: none;

  @media (min-width: 800px) {
    display: block;
    position: absolute;
    top: calc(100vh - 100px);
    width: 100%;
  }
`

export const BackgroundImage = styled.div`
  display: none;

  & > img {
    margin: 100px 0;
  }

  @media (min-width: 1280px) {
    display: block;
    position: absolute;
    top: 200px;
    width: 100%;
    height: 1050px;
    overflow: hidden;
    z-index: 0;
  }
`

export const BackgroundImagePusher = styled.div`
  height: 1050px;
`

export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(118deg, #ff8b8b 2%, #0095db 98%);
  opacity: 0.75;
  z-index: 0;
`
