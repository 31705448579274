import React, { useState } from "react"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import { StyledBackgroundComponent } from "../../../../components/Primitives/BackgroundImage"

import { SliderContainer, SliderSlide } from "./slider-styles"

export const Slider = ({ data }) => {
  const [slide, setSlide] = useState(["slide 1", "slide 2", "slide 3"])
  const [swiper, updateSwiper] = useState(null)

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const params = {
    spaceBetween: 50,
    slidesPerView: 1.3,
    loop: true,
  }

  return (
    <SliderContainer>
      <Swiper {...params} getSwiper={updateSwiper}>
        {data &&
          data.map((slide, index) => {
            return (
              <div key={index} onClick={goNext}>
                <StyledBackgroundComponent
                  gradientOverlay
                  imageData={slide.slide_image.localFile.childImageSharp.fluid}
                >
                  <SliderSlide />
                </StyledBackgroundComponent>
              </div>
            )
          })}
      </Swiper>
    </SliderContainer>
  )
}

export default Slider
