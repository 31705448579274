import styled from "styled-components"

export const SliderContainer = styled.article`
  margin-left: 20px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 800px) {
    width: 550px;
  }
`

export const SliderSlide = styled.div`
  height: 400px;

  @media (min-width: 800px) {
    height: 500px;
  }
`
