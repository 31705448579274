import styled from "styled-components"

export const TextFullWidthWrapper = styled.section`
  min-height: 300px;
  width: 100%;
  background-color: ${props => props && props.bgColor};
  padding: 20px 0;
`
export const TextFullWidthContainer = styled.div`
  width: calc(100% - 20px);
  @media (min-width: 800px) {
    margin-top: 100px;
  }
`

export const TextFullWidthHeading = styled.div`
  width: calc(100% - 20px);
  margin: 0 20px;
  font-family: "HelveticaNeue Condensed";
  font-size: 1.3rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: 12.5px;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;

  & > h3 {
    margin: 0;
  }
  @media (min-width: 800px) {
    font-size: 2rem;
  }
`
export const TextFullWidthBody = styled.div`
  width: calc(100% - 20px);
  margin: 0 20px;

  & > * {
    color: #ffffff;
  }
  @media (min-width: 800px) {
    column-count: 2;
    column-gap: 40px;
    width: 100%;
    max-width: 1000px;
    margin: 20px;
    & > p {
      margin-top: 0px;
      display: block;
      vertical-align: top;
    }
  }
`

export const TextFullWidthButtonContainer = styled.div`
  margin: 0 20px;

  & > a {
    & > button {
      margin: 10px 0;
    }
    width: 300px;
  }

  @media (min-width: 800px) {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 100px;
    & > * + * {
      margin-left: 20px;
    }
  }
`
