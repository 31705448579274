import React from "react"

import { LabelWrapper, LeftBorder, RightBorder, Text } from "./label-styles"

import redLabel from "../../../../../images/label-red-card.svg"
import blueLabel from "../../../../../images/label-blue-card.svg"

export const Label = ({ labelText, bgColor }) => {
  const label = bgColor === "#39a5d6" ? blueLabel : redLabel

  return (
    <LabelWrapper>
      <LeftBorder label={label} />
      <Text>{labelText}</Text>
      <RightBorder label={label} />
    </LabelWrapper>
  )
}
