import React from "react"
import i18n from "../../../../config/i18n"

// styled components
import {
  TextFullWidthWrapper,
  TextFullWidthContainer,
  TextFullWidthHeading,
  TextFullWidthBody,
  TextFullWidthButtonContainer,
} from "./textfullwidth-styles"

// components
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Button } from "../../../components/Button"
import { PortView } from "../../slices-styles"
import { LocalizedLink } from "../../../components/Primitives/LocalizedLink"
import { LocaleContext } from "../../../components/Layout"

export const TextFullWidth = ({ data: { primary } }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const staticText = i18n[locale].intakeForm

  const {
    text_heading,
    text_body,
    left_button_text,
    right_button_text,
    background_color,
    anchor_id,
  } = primary

  return (
    <>
      <TextFullWidthWrapper id={anchor_id} bgColor={background_color}>
        <PortView>
          <TextFullWidthContainer>
            <TextFullWidthHeading
              dangerouslySetInnerHTML={{
                __html: text_heading && text_heading.html,
              }}
            />
            <TextFullWidthBody
              dangerouslySetInnerHTML={{ __html: text_body && text_body.html }}
            />
          </TextFullWidthContainer>
          <TextFullWidthButtonContainer>
            <LocalizedLink to={"/" + anchor_id}>
              <Button noPadding primary text={left_button_text} />
            </LocalizedLink>
            <AnchorLink to={staticText.anchorLink}>
              <Button noPadding text={right_button_text} />
            </AnchorLink>
          </TextFullWidthButtonContainer>
        </PortView>
      </TextFullWidthWrapper>
    </>
  )
}

export default TextFullWidth
