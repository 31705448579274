import React, { useState } from "react"
import { useTrail } from "react-spring"
import { Waypoint } from "react-waypoint"

import i18n from "../../../../config/i18n"
import { LocaleContext } from "../../../components/Layout"

import { Review } from "./Review"
import { ReviewsWrapper, ReviewButton, ButtonContainer } from "./reviews-styles"

export const Reviews = ({ data }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const staticText = i18n[locale].reviews

  const [on, toggle] = useState(false)

  const trail = useTrail(data.items.length, {
    transform: on ? "translateX(0)" : "translateX(300%)",
  })

  return (
    <>
      <Waypoint topOffset="-20%" onEnter={() => toggle(true)} />
      <ReviewsWrapper>
        {data &&
          trail.map((props, index) => {
            if (
              typeof window !== "undefined" &&
              window.innerWidth < 800 &&
              index > 1
            ) {
              return
            }
            return (
              <Review
                staticText={staticText}
                animation={props}
                key={index}
                data={data.items[index]}
              />
            )
          })}
      </ReviewsWrapper>

      <ButtonContainer>
        <ReviewButton
          href="https://www.google.com/maps/place/Inner+strength+Amsterdam+%7C+Personal+Training+Amsterdam/@52.3740588,4.9365499,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x2454c56906bf99ff!8m2!3d52.3740588!4d4.9387386!9m1!1b1"
          target="_blank"
          noPadding
          primary
        >
          {staticText.readReviews}
        </ReviewButton>
        <ReviewButton
          href="https://www.google.com/maps/place/Inner+strength+Amsterdam+%7C+Personal+Training+Amsterdam/@52.3740588,4.9365499,17z/data=!3m1!4b1!4m7!3m6!1s0x0:0x2454c56906bf99ff!8m2!3d52.3740588!4d4.9387386!9m1!1b1"
          target="_blank"
          noPadding
        >
          {staticText.leaveReview}
        </ReviewButton>
      </ButtonContainer>
    </>
  )
}

export default Reviews
