import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

export const BackgroundComponent = props => {
  return (
    <BackgroundImage
      fadeIn
      Tag={props.tag}
      fluid={props.imageData}
      backgroundColor={`#040e18`}
    >
      {props.children}
      {props.gradientOverlay && <GradientOverlay />}
    </BackgroundImage>
  )
}

export const StyledBackgroundComponent = styled(BackgroundComponent)`
  position: relative;
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`
export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(118deg, #ff8b8b 2%, #0095db 98%);
  opacity: 0.75;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  transition: all 0.7s ease-in-out;
  &:hover {
    opacity: 0;
  }
`

export default StyledBackgroundComponent
