import React, { useState, useEffect } from "react"
import { useSpring } from "react-spring"
import { CardWrapper, Heading, Body, Button } from "./card-styles"
import { LocalizedLink } from "../../../../components/Primitives/LocalizedLink"

import { Label } from "./Label"

const config = { tension: 1000, friction: 200 }

export const Card = ({
  labelText,
  bgColor,
  heading,
  body,
  buttonText,
  linkTo,
}) => {
  const animation = useSpring({
    config,
    to: { transform: "translate3d(0, 0,0)", opacity: 1 },
    from: { transform: "translate3d(0, 100px,0)", opacity: 0 },
    delay: 800,
  })
  return (
    <CardWrapper style={animation} bgColor={bgColor}>
      <div>
        <Heading
          dangerouslySetInnerHTML={{ __html: heading && heading.html }}
        />
        <Body dangerouslySetInnerHTML={{ __html: body && body.html }} />
      </div>
      <LocalizedLink to={"/" + linkTo}>
        <Button>{buttonText && buttonText}</Button>
      </LocalizedLink>
      <Label bgColor={bgColor} labelText={labelText} />
    </CardWrapper>
  )
}
